import React, { useState } from "react";
import { useSelector } from "react-redux";
import { emitARDNALabelEvent } from "../lib/google-analytics-events";
import {
  ARDNA_CATEGORY_USER,
  ARDNA_ACTION_BLOCKCHAIN_DETAILS,
  ARDNA_ACTION_PDF,
} from "../lib/ardna-analytics-helper";
import ImgResponsive from "./ImgResponsive";
import BlockchainInfo from "./BlockchainInfo";

const EventModal = ({ event, display, attachment, bottleId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const imageName = `${event.heading.toLowerCase().replace(/\s/g, "")}`;
  const useGA = useSelector((state) => state.useGA);
  const transactionHash = useSelector(
    (state) => state.data[event.transactionHash]
  );

  const handleShowModal = () => {
    if (!modalVisible) {
      if (useGA) {
        emitARDNALabelEvent(
          ARDNA_CATEGORY_USER,
          `${ARDNA_ACTION_BLOCKCHAIN_DETAILS} : ${event.heading}`,
          bottleId
        );
      }
      setModalVisible(true);
      document.querySelector("body").style.overflow = "hidden";
    }
  };

  const handleCloseModal = () => {
    if (modalVisible) {
      setModalVisible(false);
      document.querySelector("body").style.overflow = "visible";
    }
  };

  const getImgEnding = (imageName) => {
    let ending = "";
    switch (imageName) {
      case "thebarley":
      case "mashing":
      case "distillation":
        ending = ".gif";
        break;
      default:
        ending = ".jpg";
    }
    return ending;
  };

  return (
    <>
      <div className={`event-wrapper ${event.selected ? `` : display}`}>
        <div id="event" className="event-card">
          {event.img && (
            <>
              <ImgResponsive
                Small={`../assets/${imageName}${getImgEnding(imageName)}`}
                Large={`../assets/${imageName}-desktop${getImgEnding(
                  imageName
                )}`}
                Alt={event.heading}
                className="img-responsive"
              />
            </>
          )}
          <div className="event-card__description">
            <h6 className="event-heading">{event.heading}</h6>
            <p>{event.description}</p>
            {event.blockchainLink && (
              <p className="link" onClick={handleShowModal}>
                {event.blockchainLink}
              </p>
            )}
            {event.attachment && (
              <a
                href={event.attachment.url}
                target="_blank no_referral"
                onClick={() => {
                  if (useGA) {
                    emitARDNALabelEvent(
                      ARDNA_CATEGORY_USER,
                      ARDNA_ACTION_PDF,
                      bottleId
                    );
                  }
                }}
              >
                {event.attachment.text}
              </a>
            )}
          </div>
        </div>
      </div>

      <div>
        {modalVisible && (
          <BlockchainInfo
            transactionHash={transactionHash}
            handleCloseModal={handleCloseModal}
            additionalContent={event.additionalContent}
            userNameOverride={event.userNameOverride}
            dateOverride={event.dateOverride}
          />
        )}
      </div>
    </>
  );
};

export default EventModal;
