import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import { Button } from "react-bootstrap";

export default (props) => {
  const { text, modifier, href, children, ...rest } = props;
  return (
    <Link to={{ hash: href }}>
      <Button className={`ardna-button ardna-button--${modifier}`} {...rest}>
        {text}
        {children}
      </Button>
    </Link>
  );
};
