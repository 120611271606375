import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { emitARDNALabelEvent } from "../lib/google-analytics-events";
import {
  ARDNA_CATEGORY_USER,
  ARDNA_ACTION_CLICKED_TRACEABILITY,
  ARDNA_ACTION_CLICKED_NEWSLETTER,
  ARDNA_ACTION_BLOCKCHAIN_DETAILS,
} from "../lib/ardna-analytics-helper";
import { HashLink as Link } from "react-router-hash-link";
import BlockchainInfo from "./BlockchainInfo";
import Logo from "../assets/logo_ardnamurchan-distillery.svg";
import SignUp from "../assets/icon-send-whisky.svg";
import Button from "./Button";
import PropTypes from "prop-types";

const YourBottle = ({
  campaign,
  campaignVariant,
  bottleId,
  bottleNumber,
  numBottlesInRun,
  bottledBy,
  bottlingDate,
  bottleHash,
}) => {
  const useGA = useSelector((state) => state.useGA);
  const setImage = () => {
    let image = "";
    switch (campaign) {
      case "Tasting Pack":
        image = "tasting.jpg";
        break;
      case "Single Malt":
        image = campaignVariant === "ADSM2021-1" ? "singlemalt-01.21:01.jpg" : "singlemalt.jpg";
        break;
      case "Single Cask":
        image = "singlecask.jpg";
        break;
      default:
        image = "placeholder.png";
    }
    return image;
  };
  const [modalVisible, setModalVisible] = useState(false);

  const handleShowModal = () => {
    if (!modalVisible) {
      if (useGA) {
        emitARDNALabelEvent(
          ARDNA_CATEGORY_USER,
          `${ARDNA_ACTION_BLOCKCHAIN_DETAILS} : Bottle Details`,
          bottleId
        );
      }
      setModalVisible(true);
      document.querySelector("body").style.overflow = "hidden";
    }
  };

  const handleCloseModal = () => {
    if (modalVisible) {
      setModalVisible(false);
      document.querySelector("body").style.overflow = "visible";
    }
  };

  const renderYourBottle = () => {
    let YourBottle = <></>;
    switch (campaign) {
      case "Tasting Pack":
        YourBottle = (
          <>
            <h1 className="heading heading--your-bottle">
              Your Tasting Pack
            </h1>
          </>
        );
        break;
      case "Single Cask":
        YourBottle = (
          <>
            <h1 className="heading heading--your-bottle">
              Your bottle is one of 
            </h1>
            <h2 className="heading heading--bottle-id">
              696 bottles
            </h2>
            <h3 className="heading heading--malt-id">
              of AD/CK.335 (07:15)
            </h3>
          </>
        );
        break;
      default:
        YourBottle = (
          <>
            <h1 className="heading heading--your-bottle">
              Your Bottle
            </h1>
            <h2 className="heading heading--bottle-id">
              {`${bottleNumber}${numBottlesInRun ? `/${numBottlesInRun}` : ''}`}
            </h2>
            <h3 className="heading heading--malt-id">
              Of AD/{campaign}
            </h3>
          </>
        );
    }
    return YourBottle;
  }

  return (
    <>
      <div id="your-bottle">
        <div className="section section--img section--slim">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <img
                  className="img-responsive logo"
                  src={Logo}
                  alt="Arndamurchan Distillery"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section section--slim">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 offset-sm-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <img
                  className="img-responsive bottle"
                  src={`../assets/ardnamurchan-${setImage()}`}
                  alt="Arndamurchan Distillery"
                />
                {renderYourBottle()}
                <div className="line-decoration"></div>
                <p className="bodycopy">
                  This whisky is honed and inspired by the finest and most
                  spectacular location of any distillery in Scotland -
                  Ardnamurchan
                </p>
              </div>
            </div>
            {campaign !== "Tasting Pack" && (
              <>
                <div className="row">
                  <div className="col-10 offset-1 col-sm-6 offset-sm-3">
                    <h4 className="bottlingInfo">
                      <span className="bottlingInfo--left">Bottled by:{" "}</span>
                      <span
                        onClick={handleShowModal}
                        className="bottlingInfo--gold"
                      >
                       {bottledBy === "Lewis Hamilton" && (
                         <>
                          {" "}
                          <img
                            alt="bottled-by"
                            className="bottlingInfo__icon"
                            src={`../assets/${bottledBy
                              .toLowerCase()
                              .replace(/\s/g, "")}.png`}
                          ></img>
                          {" "}
                        </>
                       )} 
                        {bottledBy}
                      </span>
                    </h4>

                    <h4 className="bottlingInfo">
                      <span className="bottlingInfo--left">Bottled on:{" "}</span>
                      <span
                        onClick={handleShowModal}
                        className="bottlingInfo--gold bottlingInfo--gold--last"
                      >
                        {moment(bottlingDate).format("Do")} of{" "}
                        {moment(bottlingDate).format("MMMM YYYY")}
                      </span>
                    </h4>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 offset-sm-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                    <Button
                      onClick={() => {
                        if (useGA) {
                          emitARDNALabelEvent(
                            ARDNA_CATEGORY_USER,
                            ARDNA_ACTION_CLICKED_TRACEABILITY,
                            bottleId
                          );
                        }
                      }}
                      href={"true-traceability"}
                      modifier={"ghost-dark"}
                    >
                      <p>
                        DISCOVER COMPLETE
                        <span className="u-block">TRACEABILITY</span>
                      </p>
                    </Button>
                  </div>
                </div>
              </>
            )}
            <div className="row">
              <div className="col-sm-12 offset-sm-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <Link
                  to={{ hash: "sign-up" }}
                  onClick={() => {
                    if (useGA) {
                      emitARDNALabelEvent(
                        ARDNA_CATEGORY_USER,
                        ARDNA_ACTION_CLICKED_NEWSLETTER,
                        bottleId
                      );
                    }
                  }}
                >
                  <p className="sign-up">
                    Sign up for our latest news{" "}
                    <img src={SignUp} alt="Sign Up" />
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {modalVisible && (
          <BlockchainInfo
            transactionHash={bottleHash}
            handleCloseModal={handleCloseModal}
            userNameOverride={campaign === "Single Cask" || campaignVariant === "ADSM2021-1" ? "Kelly Combe": "Lewis Hamilton"}
            dateOverride={campaignVariant === "ADSM2021-1" ? "2021-01-19" : undefined}
          />
        )}
      </div>
    </>
  );
};

YourBottle.propTypes = {
  history: PropTypes.object.isRequired,
};

export default YourBottle;
