import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { emitARDNALabelEvent } from "../lib/google-analytics-events";
import {
  ARDNA_CATEGORY_PAGEVIEW,
  ARDNA_ACTION_BOTTLE_NOT_FOUND,
} from "../lib/ardna-analytics-helper";
import { useSelector } from "react-redux";
import logo from "../assets/logo_ardnamurchan-distillery.svg";

const BottleNotFound = () => {
  const useGA = useSelector((state) => state.useGA);
  const intersectTarget = useRef(null);
  let [intersectCount, setIntersectCount] = useState(0);

  useEffect(() => {
    const opts = {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    };
    // if (intersectCount < 2) {
    const callback = (list) => {
      list.forEach((entry) => {
        if (entry.isIntersecting) {
          setIntersectCount(intersectCount++);

          if (intersectCount === 1) {
            if (useGA) {
              emitARDNALabelEvent(
                ARDNA_CATEGORY_PAGEVIEW,
                ARDNA_ACTION_BOTTLE_NOT_FOUND,
                "N/A"
              );
            }
          }
        }
      });
    };
    const observerScroll = new IntersectionObserver(callback, opts);
    observerScroll.observe(intersectTarget.current);
    // }
  }, [intersectCount, useGA]);

  return (
    <div id="bottle-not-found" ref={intersectTarget}>
      <div className="content">
        <div className="border">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 flex-column">
                <img src={logo} alt="Ardnamurchan Distillery" />
                <h1 className="header">Bottle not found</h1>
                <p>Sorry, we're unable to find a bottle with this code</p>
                <p className="tcs">
                  You can find out{" "}
                  <a href="https://www.adelphidistillery.com/">
                    more about us here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BottleNotFound.propTypes = {
  history: PropTypes.object.isRequired,
};

export default BottleNotFound;
