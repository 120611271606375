import * as types from "./action-types";

export const setAuth = (auth) => ({ type: types.SET_AUTH, auth });
export const setCampaign = (campaign) => ({
  type: types.SET_CAMPAIGN,
  campaign,
});
export const setCampaignVariant = (campaignVariant) => ({
  type: types.SET_CAMPAIGN_VARIANT,
  campaignVariant,
});
export const setNavbarVisible = (visible) => ({
  type: types.SET_NAVBAR_VISIBLE,
  visible,
});
export const setModalVisible = (visible) => ({
  type: types.SET_MODAL_VISIBLE,
  visible,
});
export const incrementAnimation = (increment) => ({
  type: types.SET_ANIMATION,
  increment,
});
export const setGA = (GA) => ({ type: types.SET_GA, GA });
export const setData = (data) => ({ type: types.SET_DATA, data });
export const setBottleId = (id) => ({ type: types.SET_BOTTLE_ID, id });
