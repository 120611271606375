import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import AgeGuard from "../pages/AgeGuard";
import LandingPage from "../pages/LandingPage";
import BottleNotFound from "../pages/BottleNotFound";

const PrivateRoute = ({ component: Component, auth, ...children }) => (
  <Route
    {...children}
    render={({ history, ...props }) => {
      if (!auth) history.replace("/");
      return auth ? <Component {...props} /> : null;
    }}
  />
);

const RouterProvider = ({ auth }) => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={AgeGuard} />
        <Route path="/bottle-not-found" exact component={BottleNotFound} />
        <Switch>
          <PrivateRoute
            auth={auth}
            path="/landing-page"
            component={LandingPage}
          />
        </Switch>
      </Switch>
    </Router>
  );
};

export default RouterProvider;
