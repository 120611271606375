import React from "react";

export default function Spinner({ colour }) {
  return (
    <div class="spinner">
      <div class={`keyframes ${colour ? colour : ""}`}>
        <div></div>
      </div>
    </div>
  );
}
