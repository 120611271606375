import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../store/actions";
import PropTypes from "prop-types";
import { emitARDNALabelEvent } from "../lib/google-analytics-events";
import {
  ARDNA_CATEGORY_PAGEVIEW,
  ARDNA_ACTION_AGEGUARD,
  ARDNA_CATEGORY_USER,
  ARDNA_ACTION_CONFIRMED_AGE,
} from "../lib/ardna-analytics-helper";
import Button from "../components/Button";
import logo from "../assets/taste-the-story-logo.svg";

const AgeGuard = ({ history }) => {
  const [visible, setVisibility] = useState(true);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const campaign = useSelector((state) => state.campaign);
  const data = useSelector((state) => state.data);
  const bottleId = useSelector((state) => state.bottleId);
  const useGA = useSelector((state) => state.useGA);
  const intersectTarget = useRef(null);
  let [intersectCount, setIntersectCount] = useState(0);

  useEffect(() => {
    const opts = {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    };
    const callback = (list) => {
      list.forEach((entry) => {
        if (entry.isIntersecting) {
          setIntersectCount(intersectCount++);

          if (intersectCount === 1) {
            if (useGA) {
              emitARDNALabelEvent(
                ARDNA_CATEGORY_PAGEVIEW,
                ARDNA_ACTION_AGEGUARD,
                bottleId
              );
            }
          }
        }
      });
    };
    const observerScroll = new IntersectionObserver(callback, opts);
    observerScroll.observe(intersectTarget.current);
  }, [bottleId, intersectCount, useGA]);

  useEffect(() => {
    if (!data || (!data.bottleNumber && campaign !== "Tasting Pack")) {
      history.replace("/bottle-not-found");
    }
    if (auth === true) {
      if (useGA) {
        emitARDNALabelEvent(
          ARDNA_CATEGORY_USER,
          ARDNA_ACTION_CONFIRMED_AGE,
          bottleId
        );
      }
      history.replace("/landing-page");
    }
    if (auth === "invalid") {
      setVisibility(false);
    }
  }, [auth, history, useGA, bottleId, data, campaign]);

  const onAgeCheckComplete = async () => {
    await dispatch(setAuth(true));
    history.replace("/landing-page");
  };

  const onAgeCheckInvalid = async () => {
    await dispatch(setAuth("invalid"));
    setVisibility(false);
  };
  return (
    <div id="ageguard" ref={intersectTarget}>
      <div className="content">
        <div className="border">
          <div className="flex-container">
            <div className="flex-item-1">
              <img src={logo} alt="Ardnamurchan Distillery" />
            </div>
            <div className="flex-item-2">
              <h1 className="header">Welcome to </h1>
              <h1 className="header header--large">Ardnamurchan</h1>
              {visible ? (
                <p>Are you old enough to drink alcohol in your country?</p>
              ) : (
                <p>
                  Sorry, you have to be of legal drinking age to visit this site
                </p>
              )}
              {visible && (
                <div className="button-wrapper">
                  <Button
                    text={"Yes"}
                    onClick={() => {
                      onAgeCheckComplete();
                    }}
                  />
                  <Button
                    text={"No"}
                    modifier={"ghost"}
                    onClick={() => {
                      onAgeCheckInvalid();
                    }}
                  />
                </div>
              )}
            </div>
            <p className="tcs">
              By entering you accept our{" "}
              <a
                href="https://www.adelphidistillery.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Notice.
              </a>{" "}
              For information on alcohol responsibility, visit{" "}
              <a
                href="https://www.drinkaware.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                drinkaware.co.uk.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

AgeGuard.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AgeGuard;
