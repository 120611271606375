
import config from '../const/config';

export const getTrackingInformation = async (qrCode) => {
  let trackingProxyBaseUrl =  config.trackingProxyBaseUrl;
  let trackingProxyFullUrl = trackingProxyBaseUrl + '?qrCode=' + encodeURIComponent(qrCode);

  const res = await fetch(trackingProxyFullUrl);
  if (res.status === 200) {
    const content = await res.json();
    return content;
  }
  return {};
}