import React, { useState, useEffect } from "react";
import moment from "moment";
import config from "../const/config";
import Spinner from "./Spinner";
import Close from "../assets/icon-close.svg";

export default function BlockchainInformation({
  transactionHash,
  handleCloseModal,
  additionalContent,
  userNameOverride,
  dateOverride,
}) {
  const [blockchainData, setBlockchainData] = useState(null);
  const handleError = (error) => {
    return Promise.reject(error.message || error);
  };

  const handleModal = (e) => {
    handleCloseModal();
  };
  useEffect(() => {
    const getBlockChainData = async () => {
      const blockchainProxyFullUrl = `${config.blockchainProxyBaseUrl}?transactionHash=${transactionHash}&brand=adelphidmp`;
      try {
        const response = await fetch(blockchainProxyFullUrl, {
          method: "GET",
          headers: { responseType: "json" },
        });
        if (response.ok) {
          const res = await response.json();
          setBlockchainData(res);
        }
      } catch (err) {
        handleError(err);
      }
    };
    getBlockChainData();
  }, [transactionHash]);
  return (
    <>
      <div className="bc-modal">
        <div className="bc-modal-inner">
          <img
            src={Close}
            onClick={handleModal}
            className="close"
            alt="Close modal"
          />
          <h6 className="event-heading">Blockchain Information</h6>

          {blockchainData ? (
            <>
              <p>
                User Name:
                <span> {userNameOverride ? userNameOverride : blockchainData.data.userName}</span>
              </p>

              <p>
                Event Name:
                <span> {blockchainData.data.eventName.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })}</span>
              </p>
              {additionalContent && additionalContent.map((item) => (
                <p>
                  {`${item.key}:`}
                <span> {`${item.value}`}</span>
                </p>
              ))

              }

              <p>
                Event Date:
                <span>
                  {" "}
                  {moment(dateOverride ? dateOverride : blockchainData.data.eventDate).format(
                    "DD-MM-YYYY"
                  )}
                </span>
              </p>
              <p>
                Transaction Hash:
                <span> {transactionHash}</span>
              </p>

              <p>
                Block Index:
                <span> {blockchainData.blockIndex}</span>
              </p>

              <p>
                Confirmations:
                <span> {blockchainData.confirmations}</span>
              </p>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      <div className="bc-modal-overlay" onClick={handleModal}></div>
    </>
  );
}
