import ReactGA from "react-ga";

const emitARDNAEvent = (eventCategory, eventAction) => {
  ReactGA.event({
    category: eventCategory,
    action: eventAction,
  });
};

const emitARDNALabelEvent = (eventCategory, eventAction, eventLabel) => {
  ReactGA.event({
    category: eventCategory,
    action: eventAction,
    label: eventLabel,
  });
};
export { emitARDNAEvent, emitARDNALabelEvent };
