import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import ImgResponsive from "./ImgResponsive";
import Large from "./../assets/riverflow.gif";
import Small from "./../assets/riverflow.gif";

const GreenDistillery = ({ bottleId }) => {
  const intersectTarget = useRef(null);
  let [intersectCount, setIntersectCount] = useState(0);

  useEffect(() => {
    const opts = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    // if (intersectCount < 2) {
    const callback = (list) => {
      list.forEach((entry) => {
        if (entry.isIntersecting) {
          setIntersectCount(intersectCount++);

          if (intersectCount === 1) {
            ReactGA.event({
              category: "Scroll",
              action: "Scrolled to Green Distillery",
              label: bottleId,
            });
          }
        }
      });
    };
    const observerScroll = new IntersectionObserver(callback, opts);
    observerScroll.observe(intersectTarget.current);
    // }
  }, [bottleId]);

  return (
    <div ref={intersectTarget} className="GreenDistillery">
      <div className="section section--green">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="header">
                Ardnamurchan
                <span className="header--large">The Green Distillery</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="section section--dark section--slim">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 offset-sm-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <ImgResponsive
                className="img-responsive video"
                Small={Small}
                Large={Large}
                Alt="Whisky pouring into a glass"
              />
              <p className="bodycopy">
                Whisky is a natural product and we've ensured this is reflected
                in the way we operate. Uniquely in the whisky industry, all the
                power and heat requirements for the Ardnamurchan Distillery come
                from local renewables - the river that provides the distillery's
                cooling water has a hydro-electricity generator and the biomass
                boiler is fuelled by woodchip from local forestry - the overall
                footprint of the distillery is a light one.
              </p>

              <p className="bodycopy">
                In addition, the by-products of the whisky-making process are
                recycled on the peninsula - the still energy-rich draff (what is
                left in the mash tun) is combined with the pot ale to produce
                animal feed for the use on the peninsula.'
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

GreenDistillery.propTypes = {
  history: PropTypes.object.isRequired,
};

export default GreenDistillery;
