import React, { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga";
import { emitARDNALabelEvent } from "../lib/google-analytics-events";
import {
  ARDNA_CATEGORY_PAGEVIEW,
  ARDNA_ACTION_LANDING,
} from "../lib/ardna-analytics-helper";
import { useSelector } from "react-redux";
import YourBottle from "../components/YourBottle";
import GreenDistillery from "../components/GreenDistillery";
import TastingNotes from "../components/TastingNotes";
import SocialLinks from "../components/SocialLinks";
import TrueTraceability from "../components/TrueTraceability";

const LandingPage = () => {
  const data = useSelector((state) => state);
  const useGA = useSelector((state) => state.useGA);
  const intersectTarget = useRef(null);
  let [intersectCount, setIntersectCount] = useState(0);
  console.log(data)
  useEffect(() => {
    const opts = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };
    // if (intersectCount < 2) {
    const callback = (list) => {
      list.forEach((entry) => {
        if (entry.isIntersecting) {
          setIntersectCount(intersectCount++);

          if (intersectCount === 1) {
            if (useGA) {
              console.log("page view landing");
              emitARDNALabelEvent(
                ARDNA_CATEGORY_PAGEVIEW,
                ARDNA_ACTION_LANDING,
                data.bottleId
              );
            }
          }
        }
      });
    };
    const observerScroll = new IntersectionObserver(callback, opts);
    observerScroll.observe(intersectTarget.current);
    // }
  }, [data.bottleId, intersectCount, useGA]);

  return (
    <div className="landingpage" ref={intersectTarget}>
      <YourBottle
        campaign={data.campaign}
        campaignVariant={data.campaignVariant}
        bottleNumber={data.data.bottleNumber}
        numBottlesInRun={data.data.numBottlesInRun}
        bottledBy={data.data.bottledBy}
        bottlingDate={data.data.bottlingDate}
        bottleId={data.bottleId}
        bottleHash={data.data.registerBottleHash}
      />
      <GreenDistillery bottleId={data.bottleId} />
      <TastingNotes
        campaign={data.campaign}
        campaignVariant={data.campaignVariant}
        tastingNotes={data.data.tastingNotes}
        bottleId={data.bottleId}
      />
      {data.campaign !== "Tasting Pack" && (
        <TrueTraceability
          bottleId={data.bottleId}
          campaign={data.campaign}
          campaignVariant={data.campaignVariant}
        />
      )}
      <SocialLinks bottleId={data.bottleId} />
    </div>
  );
};

export default LandingPage;
