import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import RouterProvider from "./providers/RouterProvider";
import LoadingSpinner from "./components/LoadingSpinner";
import { getTrackingInformation } from "./services/trackingService";
import { setBottleId, setCampaign, setData, setGA, setCampaignVariant } from "./store/actions";

const App = () => {
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const url = window.location.search;
  const qrCode = document.location.toString();
  let params = new URLSearchParams(url);
  let campaignParam = params.get("utm_campaign");
  let bottleParam = params.get("id");

  // run the data report
  useEffect(() => {
    const getData = async () => {
      const data = await getTrackingInformation(qrCode);
      if (!!data && qrCode.indexOf("DEMO") < 0) {
        dispatch(setGA(true));
      }
      dispatch(setData(data));
      setLoading(false);
    };
    if (campaignParam === "ADSM2020-mini") {
      setLoading(false);
    } else {
      getData();
    }
  }, [campaignParam, dispatch, qrCode]);

  // set the campaign
  useEffect(() => {
    let campaign = "";
    const getCampaign = (campaignParam) => {
      switch (campaignParam) {
        case "ADSM2020-mini":
          campaign = "Tasting Pack";
          break;
        case "ADSM2021-1":
        case "ADSM2020":
          campaign = "Single Malt";
          break;
        case "ADCK335":
          campaign = "Single Cask";
          break;
        default:
          campaign = null;
      }
      return campaign;
    };
    getCampaign(campaignParam);
    dispatch(setCampaign(campaign));
    dispatch(setBottleId(bottleParam));
    dispatch(setCampaignVariant(campaignParam));
  }, [campaignParam, bottleParam, dispatch, loading]);

  return (
    <div className="App">
      {!loading ? <RouterProvider auth={auth} /> : <LoadingSpinner />}
    </div>
  );
};

export default App;
