import React from "react";
import ReactGA from "react-ga";
import ReactDOM from "react-dom";
import App from "./App";
import reducers, { initialState } from "./store/reducers";
import { Provider } from "react-redux";
import { createStore } from "redux";
import * as serviceWorker from "./serviceWorker";
import "./styles/main.scss";

// create the redux store
const store = createStore(reducers, initialState);
ReactGA.initialize("UA-106312868-6", { debug: false });
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
