import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  ARDNA_CATEGORY_USER,
  ARDNA_ACTION_PLAY_SOUNDBITE,
  ARDNA_ACTION_NEWSLETTER_SIGNUP,
  ARDNA_ACTION_CLICKED_OUTBOUND,
} from "../lib/ardna-analytics-helper";
import { emitARDNALabelEvent } from "../lib/google-analytics-events";
import PropTypes from "prop-types";
import config from "../const/config";
import Spinner from "./Spinner";
import logo from "../assets/taste-the-story-logo.svg";
import soundbiteLogo from "../assets/ard-na-murkan.svg";
import play from "../assets/icon-play.svg";
import soundbite from "../assets/ardnamurchan.mov";
import Twitter from "../assets/icon-twitter.svg";
import Facebook from "../assets/icon-facebook.svg";
import LinkedIn from "../assets/icon-linkedin.svg";
import Instragram from "../assets/icon-instagram.svg";
import Youtube from "../assets/icon-youtube.svg";
import Submit from "../assets/icon-send-white.svg";

const SocialLinks = ({ bottleId }) => {
  const [emailValue, setEmailValue] = useState("");
  const [variant, setVariant] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const useGA = useSelector((state) => state.useGA);
  const handleChange = (event) => {
    setEmailValue(event.target.value);
  };
  const intersectTarget = useRef(null);
  let [intersectCount, setIntersectCount] = useState(0);

  const successfulAnimation = (data) => {
    setVariant("success");
    setEmailMessage(
      data.error === "Contact already exist"
        ? "You have already registered this email"
        : "Thank you, you have successfully registered!"
    );

    if (useGA) {
      emitARDNALabelEvent(
        ARDNA_CATEGORY_USER,
        ARDNA_ACTION_NEWSLETTER_SIGNUP,
        bottleId
      );
    }
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2500);
  };

  const handleSignUp = (event) => {
    event.preventDefault();
    if (emailValue === "") {
      setVariant("warning");
      setEmailMessage("Please enter a valid email");
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2500);
    } else {
      let emailEnding = /\.(.*)/.exec(/@(.*)/.exec(emailValue)[1]);
      if (emailEnding === null) {
        setVariant("warning");
        setEmailMessage("Please enter a valid email");
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2500);
      } else {
        setLoading(true);
        const env = process.env.NODE_ENV;
        if (env === "production") {
          fetch(`${config.emailSignUpUrl}`, {
            headers: { "content-type": "application/json" },
            method: "POST",
            body: JSON.stringify({
              email_address: emailValue,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              setLoading(false);
              successfulAnimation(data);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else {
          console.log("newsletter sign up not enabled in development");
          setLoading(false);
          successfulAnimation({ error: "Contact already exist" });
        }
      }
    }
  };

  useEffect(() => {
    const opts = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };
    // if (intersectCount < 2) {
    const callback = (list) => {
      list.forEach((entry) => {
        if (entry.isIntersecting) {
          setIntersectCount(intersectCount++);

          if (intersectCount === 1) {
            console.log("scroll");
            ReactGA.event({
              category: "Scroll",
              action: "Scrolled to Social Links",
              label: bottleId,
            });
          }
        }
      });
    };
    const observerScroll = new IntersectionObserver(callback, opts);
    observerScroll.observe(intersectTarget.current);
    // }
  }, [bottleId, intersectCount]);

  return (
    <div id="social-links" ref={intersectTarget}>
      <div className="section">
        <div className="container">
          <div className="row mini-section">
            <div className="col-sm-12 offset-sm-0">
              Our whisky is always non chill-filtered and no colour has been added to it
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 offset-sm-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <img className="logo" src={soundbiteLogo} alt="Arndamurkan" />
              <div
                className="play"
                onClick={() => {
                  if (useGA) {
                    emitARDNALabelEvent(
                      ARDNA_CATEGORY_USER,
                      ARDNA_ACTION_PLAY_SOUNDBITE,
                      bottleId
                    );
                  }
                  new Audio(soundbite).play();
                }}
              >
                <img src={play} alt="Play" />
                <p>Play</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 offset-sm-0">
              <h4 className="heading">
                We're social, <span>connect with us</span>
              </h4>
              <div className="link-container">
                <a
                  href="https://twitter.com/distillery1826"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if (useGA) {
                      emitARDNALabelEvent(
                        ARDNA_CATEGORY_USER,
                        `${ARDNA_ACTION_CLICKED_OUTBOUND} : Twitter`,
                        bottleId
                      );
                    }
                  }}
                >
                  <img src={Twitter} alt="Twitter" />
                </a>
                <a
                  href="https://www.facebook.com/ardnamurchandistillery/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if (useGA) {
                      emitARDNALabelEvent(
                        ARDNA_CATEGORY_USER,
                        `${ARDNA_ACTION_CLICKED_OUTBOUND} : Facebook`,
                        bottleId
                      );
                    }
                  }}
                >
                  <img src={Facebook} alt="Facebook" />
                </a>
                <a
                  href="https://uk.linkedin.com/company/adelphidistillery"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if (useGA) {
                      emitARDNALabelEvent(
                        ARDNA_CATEGORY_USER,
                        `${ARDNA_ACTION_CLICKED_OUTBOUND} : LinkedIn`,
                        bottleId
                      );
                    }
                  }}
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
                <a
                  href="https://www.instagram.com/ardnamurchandistillery/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if (useGA) {
                      emitARDNALabelEvent(
                        ARDNA_CATEGORY_USER,
                        `${ARDNA_ACTION_CLICKED_OUTBOUND} : Instagram`,
                        bottleId
                      );
                    }
                  }}
                >
                  <img src={Instragram} alt="Instagram" />
                </a>

                <a
                  href="https://www.youtube.com/channel/UCHW2ys4R24ZyIuYotSvy-KQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if (useGA) {
                      emitARDNALabelEvent(
                        ARDNA_CATEGORY_USER,
                        `${ARDNA_ACTION_CLICKED_OUTBOUND} : Youtube`,
                        bottleId
                      );
                    }
                  }}
                >
                  <img src={Youtube} alt="Youtube" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" section section--blue">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 offset-sm-0">
              <h4 className="mailing-header" id="sign-up">
                Join our
                <span className="mailing-header--bold">Mailing list</span>
              </h4>
              <div className="email-message">
                {loading && <Spinner colour={"white"} />}

                <Alert
                  className={`${success ? `display` : `display-none`}`}
                  variant={`${variant}`}
                >
                  <p className="alert-success__message alert-warning__message">
                    {emailMessage}
                  </p>
                </Alert>
              </div>
              <form onSubmit={handleSignUp}>
                <input
                  type="email"
                  value={emailValue}
                  onChange={handleChange}
                  className="form-control"
                  id="inputEmail"
                  aria-describedby="emailHelp"
                  placeholder="Enter your email address"
                />
                <button
                  type="submit"
                  value="Submit"
                  className="ardna-button ardna-button--form"
                >
                  <img src={Submit} alt="" />
                </button>
              </form>
              <img
                className="logo img-responsive"
                src={logo}
                alt="Ardnamurchan Distillery"
              />
              <a
                className="link"
                href="https://www.adelphidistillery.com/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  if (useGA) {
                    emitARDNALabelEvent(
                      ARDNA_CATEGORY_USER,
                      `${ARDNA_ACTION_CLICKED_OUTBOUND} : Ardnamurchan website`,
                      bottleId
                    );
                  }
                }}
              >
                ardnamurchandistillery.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SocialLinks.propTypes = {
  history: PropTypes.object.isRequired,
};

export default SocialLinks;
