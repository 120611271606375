import React from "react";

export default (props) => {
  const { Small, Large, Alt, className } = props;
  return (
    <picture>
      <source media="(min-width:650px)" srcSet={Large} />
      <source media="(min-width:465px)" srcSet={Small} />
      <img className={className} src={Small} alt={Alt} />
    </picture>
  );
};
