import React from "react";

export default function LoadingSpinner() {
  return (
    <div className="loading">
      <div className="border">
        <div class="spinner">
          <div class="keyframes">
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
