const ARDNA_CATEGORY_PAGEVIEW = "Viewed page";
const ARDNA_ACTION_CONFIRMED_AGE = "Confirmed Age";
const ARDNA_CATEGORY_YOUR_BOTTLE = "Your Bottle";
const ARDNA_CATEGORY_USER = "User";
const ARDNA_CATEGORY_TRUE_TRACEABILITY = "True Traceability";
const ARDNA_CATEGORY_EMAIL_SIGNUP = "Signed up to the newsletter";
const ARDNA_ACTION_AGEGUARD = "Viewed ageguard page";
const ARDNA_ACTION_LANDING = "Viewed landing page";
const ARDNA_ACTION_BOTTLE_NOT_FOUND = "Viewed bottle not found page";
const ARDNA_ACTION_CLICKED_TRACEABILITY =
  "Clicked to view traceability section";
const ARDNA_ACTION_CLICKED_NEWSLETTER = "Clicked to signup to newsletter";
const ARDNA_ACTION_BLOCKCHAIN_DETAILS = "Clicked to view Blockchain Details";
const ARDNA_ACTION_PDF = "Downloaded PDF";
const ARDNA_ACTION_SEE_MORE = "Clicked see more";
const ARDNA_ACTION_SEE_LESS = "Clicked see less";
const ARDNA_ACTION_PLAY_SOUNDBITE = "Played soundbite";
const ARDNA_ACTION_NEWSLETTER_SIGNUP = "Signed up to the newsletter";
const ARDNA_ACTION_CLICKED_OUTBOUND = "Clicked an outbound link";

const isDummyQRCode = (qrCode) => qrCode.indexOf("dummyhash") >= 0;

export {
  isDummyQRCode,
  ARDNA_CATEGORY_PAGEVIEW,
  ARDNA_ACTION_CONFIRMED_AGE,
  ARDNA_CATEGORY_USER,
  ARDNA_CATEGORY_YOUR_BOTTLE,
  ARDNA_CATEGORY_TRUE_TRACEABILITY,
  ARDNA_CATEGORY_EMAIL_SIGNUP,
  ARDNA_ACTION_AGEGUARD,
  ARDNA_ACTION_LANDING,
  ARDNA_ACTION_BOTTLE_NOT_FOUND,
  ARDNA_ACTION_CLICKED_TRACEABILITY,
  ARDNA_ACTION_CLICKED_NEWSLETTER,
  ARDNA_ACTION_BLOCKCHAIN_DETAILS,
  ARDNA_ACTION_PDF,
  ARDNA_ACTION_SEE_MORE,
  ARDNA_ACTION_SEE_LESS,
  ARDNA_ACTION_PLAY_SOUNDBITE,
  ARDNA_ACTION_NEWSLETTER_SIGNUP,
  ARDNA_ACTION_CLICKED_OUTBOUND,
};
