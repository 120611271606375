import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { emitARDNALabelEvent } from "../lib/google-analytics-events";
import Button from "./Button";
import EventModal from "./EventModal";
import {
  ARDNA_CATEGORY_USER,
  ARDNA_ACTION_SEE_MORE,
  ARDNA_ACTION_SEE_LESS,
} from "../lib/ardna-analytics-helper";

let traceabilityEventList = [
  {
    transactionHash: "addMaterialToBrewHash",
    heading: "The Barley",
    blockchainLink: "Barley variety used for this batch.",
    img: true,
    description:
      "Most of our barley grows in West Fife, near our head office, with the rest supplied and malted within Scotland. We use both peated and unpeated malted barley in the production of our single malt. Our malted barley is supplied by Bairds Maltings.",
    selected: true,
    additionalContent: [{ key: "Malt Name", value: "Concerto" }],
    userNameOverride: "Alex Bruce",
  },
  {
    transactionHash: "addMashWatersHash",
    heading: "Mashing",
    blockchainLink: "Mashwater temperature for this batch.",
    img: true,
    selected: true,
    description:
      "Our mashtun was individually designed to fit both our distillery, and the narrowest bridge (3.2m) when being transported across the Ardnamurchan peninsula. The malted barley is added to the mashtun along with hot water to start the mashing process.  The water is heated by our biomass boiler using wood chips from sustainable local forestry. Once our wort is ready it gets transferred to our washbacks for fermentation. The draff left over from the mashing is mixed with the evaporated pot ale and makes a nutritious animal feed for the local farm.",
    additionalContent: [
      { key: "Min Temperature", value: "64" },
      { key: "Max Temperature", value: "90" },
    ],
    userNameOverride: "Gordon Mackenzie",
  },
  {
    transactionHash: "completeFerementationHash",
    heading: "Fermentation",
    blockchainLink: "Hours of fermentation for this batch.",
    img: true,
    description:
      "If you ask any distillery that ferments in wooden washbacks what style of washbacks are best, they will tell you wooden ones are best. If you ask a distillery using stainless steel washbacks the same question, they will tell you stainless steel is best. At Ardnamurchan we opted to use both styles, we have four wooden ones, two made from oak and two from Oregon pine, and three made from stainless steel.",
    additionalContent: [{ key: "Average Fermentation Hours", value: "76" }],
    userNameOverride: "Gordon Mackenzie",
  },
  {
    transactionHash: "setSpiritStillTimeOffHash",
    heading: "Distillation",
    blockchainLink: "Spirit hydrometer readings for this batch.",
    img: true,
    description:
      "Our spirit is distilled using traditional copper stills, the wash still holds 10,000 liters and the spirit still, which has a neck with a boil ball for extra reflux, holds 6,000 litres. We use standard shell and tube condensers, unusually located outside the building, to turn the spirit vapour into liquid. Our new make spirit is cut at around 75% ABV. We use water from the Glenmore spring to dilute the spirit down to 63.5% ABV before filling the casks",
    additionalContent: [
      { key: "Min Hydrometer", value: "68.5" },
      { key: "Max Hydrometer", value: "70.5" },
    ],
    userNameOverride: "Nicky Docherty",
  },
  {
    transactionHash: "registerBottleHash",
    heading: "Maturation",
    img: true,
    description:
      "Unusually, these days, we have our own dunnage warehouses at the distillery. Our whisky matures right next to where it was made, on the Ardnamurchan peninsula, in close proximity to the North Atlantic Ocean (Ardnamurchan translates as “Headland of the Great Seas”). We believe the rugged west coast air gives our whisky a certain element that cannot be replicated elsewhere.",
    attachment: {
      url: "../assets/adelphi-single-malt-batch.pdf",
      text: "See the cask data for this batch",
    },
  },
];

const TrueTraceability = ({ bottleId, campaign, campaignVariant }) => {
  const [expandedListState, setExpandedListState] = useState(true);
  const [eventList, setEventList] = useState(false);
  const [timelineModifier, setTimelineModifier] = useState("collapse");
  const [display, setDisplay] = useState("display-none");
  const useGA = useSelector((state) => state.useGA);
  const intersectTarget = useRef(null);
  let [intersectCount, setIntersectCount] = useState(0);

  useEffect(() => {
    if (campaign === "Single Cask") {
      traceabilityEventList = traceabilityEventList.map((event) => event.heading === "Maturation" ?
        {
          ...event,
          attachment: {
            url: '../assets/adelphi-single-cask.pdf',
            text: event.attachment.text,
          }
        }
        : event);
    } else if (campaignVariant === 'ADSM2021-1') {
      traceabilityEventList = traceabilityEventList.map((event) => {
        let updatedEvent;
        switch(event.heading) {
          case "Maturation":
            updatedEvent = {
              ...event,
              attachment: {
                url: '../assets/adelphi-single-malt-batch-2.pdf',
                text: event.attachment.text,
              }
            };
            break;
          case "Mashing":
            updatedEvent = {
              ...event,
              additionalContent: [
                { key: "Min Temperature", value: "64" },
                { key: "Max Temperature", value: "94" },
              ],
              dateOverride: "2014-9-12"
            }
            break;
          case "Distillation":
            updatedEvent = {
              ...event, 
              additionalContent: [
                { key: "Min Hydrometer", value: "68.5" },
                { key: "Max Hydrometer", value: "75.2" },
              ],
              dateOverride: "2014-9-12"
            }
            break;
          default:
            updatedEvent = event;
        }
        return updatedEvent;
      })
    }
    setEventList([...traceabilityEventList]);
  }, [campaign]);

  const switchList = () => {
    setExpandedListState(!expandedListState);
    if (expandedListState === true) {
      if (useGA) {
        emitARDNALabelEvent(
          ARDNA_CATEGORY_USER,
          ARDNA_ACTION_SEE_MORE,
          bottleId
        );
      }
    } else {
      if (useGA) {
        emitARDNALabelEvent(
          ARDNA_CATEGORY_USER,
          ARDNA_ACTION_SEE_LESS,
          bottleId
        );
      }
    }
    expandedListState ? setDisplay("display") : setDisplay("display-none");
  };

  useEffect(() => {
    const opts = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    // if (intersectCount < 2) {
    const callback = (list) => {
      list.forEach((entry) => {
        if (entry.isIntersecting) {
          setIntersectCount(intersectCount++);

          if (intersectCount === 1) {
            ReactGA.event({
              category: "Scroll",
              action: "Scrolled to True Traceability",
              label: bottleId,
            });
          }
        }
      });
    };
    const observerScroll = new IntersectionObserver(callback, opts);
    observerScroll.observe(intersectTarget.current);
    // }
  }, [bottleId, intersectCount]);

  return (
    <div id="true-traceability" ref={intersectTarget}>
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 offset-sm-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h4 className="header">
                Your bottle of
                <span className="header header--bold">Whisky</span>
              </h4>
              <h4 className="header header--thin">True Traceability</h4>
              <div className="line-decoration"></div>
              <p className="description">
                Each step in creating our single malt has been carefully
                recorded and written to the blockchain as a guarantee of
                complete transparency and authenticity. It means you can trace
                each step and every process that went into creating this iconic
                whisky.
              </p>

              <div className={`test ${timelineModifier}`}>
                <div className={`timeline-wrapper`}>
                  {eventList &&
                    eventList.map((event) => {
                      return (
                        <EventModal
                          display={display}
                          event={event}
                          key={event.transactionHash}
                          bottleId={bottleId}
                        />
                      );
                    })}
                </div>
                <Button
                  modifier={`ghost-dark ${
                    expandedListState ? "" : "display-none"
                  }`}
                  id="show-more"
                  text={"Show more"}
                  onClick={switchList}
                />
                <Button
                  modifier={`ghost-dark ${
                    expandedListState ? "display-none" : ""
                  }`}
                  href="show-more"
                  text={"Show less"}
                  onClick={switchList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrueTraceability;
