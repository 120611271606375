import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga";
import ImgResponsive from "./ImgResponsive";
import Large from "./../assets/whisky-pour--desktop.gif";
import Small from "./../assets/whisky-pour--mobile.gif";

const TastingNotes = ({ tastingNotes, bottleId, campaign, campaignVariant }) => {
  const intersectTarget = useRef(null);
  let [intersectCount, setIntersectCount] = useState(0);

  useEffect(() => {
    const opts = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    // if (intersectCount < 2) {
    const callback = (list) => {
      list.forEach((entry) => {
        if (entry.isIntersecting) {
          setIntersectCount(intersectCount++);

          if (intersectCount === 1) {
            ReactGA.event({
              category: "Scroll",
              action: "Scrolled to Tasting Notes",
              label: bottleId,
            });
          }
        }
      });
    };
    const observerScroll = new IntersectionObserver(callback, opts);
    observerScroll.observe(intersectTarget.current);
    // }
  }, [bottleId, intersectCount]);

  const renderTastingNotes = () => {
    let tastingNotes = "Honeycomb, waxy peel, oyster shells, brine, strawberries with black pepper and bonfire embers.";
    if (campaign === "Single Cask") {
      tastingNotes = "Citrus zest, smoked mussels, toasted oatmeal and a maple glaze; chilli peppers, salted caramel and red apple skin.";
    }
    if (campaignVariant === "ADSM2021-1") {
      tastingNotes = (
      <>
        <span className="bold">Nose</span><span> - Nutmeg, toffee apple, Crème brûlée, linseed, Seville oranges, cardamon, Manuka honey, nougat, saltwater and driftwood.</span><br /><br />
        <span className="bold">Palate</span><span> - Grapefruit marmalade, flamed orange peel, ripe pear, peach, sweet soy sauce, gentle minerals, warming Sichuan pepper and campfire.</span>
      </>
      );
    }
    return tastingNotes;
  }
  
  return (
    <div id="tasting-notes" ref={intersectTarget}>
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 offset-sm-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 className="heading heading--primary">
                Our Tasting <span>notes</span>
              </h2>
              <h5 className="heading heading--secondary">
                Ardnamurchan Distillery - <span>Taste the Story</span>
              </h5>
              <div className="line-decoration"></div>
              <ImgResponsive
                className="img-responsive video"
                Small={Small}
                Large={Large}
                Alt="Whisky pouring into a glass"
              />
              <p>
                {renderTastingNotes()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TastingNotes;
