import * as types from "./action-types";

export const initialState = {
  auth: false,
  campaign: "",
  campaignVariant: "",
  navBarVisible: true,
  modalVisible: false,
  animationNumber: 0,
  useGA: false,
  bottleId: "adsm148520638399",
  data: {
    bottleNumber: "517",
    numBottlesInRun: "",
    bottledBy: "Kelly Combe",
    bottlingDate: "2021-01-19T09:00:00Z",
    registerBottleHash:
      "0x9e73e026f7047dfb3cd0676818eda2f07ebef046e6d685737d0d484f5e29a04f",
    addMaterialToBrewHash:
      "0x9e73e026f7047dfb3cd0676818eda2f07ebef046e6d685737d0d484f5e29a04f",
    addMashWatersHash:
      "0x9e73e026f7047dfb3cd0676818eda2f07ebef046e6d685737d0d484f5e29a04f",
    completeFermentationHash:
      "0x9e73e026f7047dfb3cd0676818eda2f07ebef046e6d685737d0d484f5e29a04f",
    setSpiritStillTimeOffHash:
      "0x9e73e026f7047dfb3cd0676818eda2f07ebef046e6d685737d0d484f5e29a04f",
  },
  tastingNotes:
    "Honeycomb, waxy peel, oyster shells, brine, strawberries with black pepper and bonfire embers.",
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_AUTH:
      return { ...state, auth: action.auth };
    case types.SET_CAMPAIGN:
      return { ...state, campaign: action.campaign };
    case types.SET_CAMPAIGN_VARIANT:
      return { ...state, campaignVariant: action.campaignVariant };
    case types.SET_NAVBAR_VISIBLE:
      return { ...state, navBarVisible: action.visible };
    case types.SET_MODAL_VISIBLE:
      return { ...state, modalVisible: action.visible };
    case types.SET_ANIMATION:
      return { ...state, animationNumber: action.increment };
    case types.SET_GA:
      return { ...state, useGA: action.GA };
    case types.SET_DATA:
      return { ...state, data: action.data };
    case types.SET_BOTTLE_ID:
      return { ...state, bottleId: action.id };
    default:
      return state;
  }
}

export default reducer;
